.adapter-pill {
    margin: 0 2px;

    display: flex;
    padding: var(--spacing-sm) var(--spacing-lg);
    align-items: center;


    border-radius: var(--radius-full);
    //border: 1px solid var(--Colors-Adapter-Purple-300);
    background-color: var(--Base-100);

    color: var(--adapter-gray-light-900);

    text-align: center;
    text-wrap: nowrap;
    
    font-family: 'Saans';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    &.highlighted {
        background: var(--Colors-Adapter-Purple-50);
    }
}