.adapter-chat-message {
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: var(--spacing-lg);

    margin: 0 auto;
    margin-bottom: 20px;
    width: 80vw;
    max-width: 600px;

    .avatar {
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: var(--radius-full);
        background: var(--adapter-gray-light-300);
        border: 0.75px solid var(--Component-colors-Components-Avatars-avatar-contrast-border);

        margin-right: 10px;

        &.adapter {
            background-color: #73788b;
        }

        .adapter-icon {
            padding: unset;
        }
    }

    .avatar-spacer {
        height: 40px;
        width: 40px;
    }

    .message {
        flex: 1;
        // need this in addition to human sacrifice to satisfy CSS layout gods
        min-width: 0;

        text-align: left;

        p {
            display: flex;
            flex-direction: row;
            line-height: 40px;
            align-items: center;
        }

        .timeago {
            margin: 0 20px;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            color: var(--adapter-gray-light-500);
            text-overflow: ellipsis;
            
            font-family: 'Saans';
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 14px;
        }

        .content {
            display: inline-flex;
            flex-direction: column;
            align-items: stretch;
            gap: 24px;

            > * {
                color: var(--adapter-gray-light-950);

                font-family: 'Saans';
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
            }

            h5, p {
                color: var(--colors-text-text-tertiary-600);

                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
            }

            h5 {
                font-family: 'Saans';

            }

            p {
                font-family: 'Saans';
            }
        }
    }

    &.user {
        align-self: end;
        flex-direction: row-reverse;

        .message {
            p {
                text-align: right;
                flex-direction: row-reverse;
            }

            .content {
                border-radius: var(--radius-md) var(--radius-none) var(--radius-md) var(--radius-md);
                float: right;
            }

            .adapter-card {
                background-color: var(--Base-200);
                border-top-right-radius: 0 !important; /* Ensure no radius in top right corner */
            }
        }
    }
}

/* 48em = 768px */
@media screen and (max-width: 48em) {
    .adapter-chat-message {
        max-width: calc(100% - 12px);

        .avatar,
        .avatar-spacer {
            display: none;
        }

        .content {
            display: unset !important;
        }

        .content .adapter-card {
            margin: 0;
        }
        
        &.user .message .adapter-card {
            border-top-right-radius: 0 !important; /* Maintain no radius in mobile view */
        }
    }
}