.intention-inputbar {
    align-self: center;
    display: flex;
    padding: var(--spacing-md) var(--spacing-md) var(--spacing-xl) var(--spacing-md);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-md);
    width: 100%;
    max-width: 770px !important;
    overflow: hidden;
    -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);

    textarea {
        border: 0;
        width: 100%;
        min-height: 50px;
        max-height: 200px;
        padding: 12px 12px 40px 12px;
        font-size: 16px;
        resize: none;
        overflow-y: auto;
        box-sizing: border-box;
        transition: height 0.2s ease;

        &:focus {
            box-shadow: unset;
            border: unset;
            outline: none;
        }

        &::-webkit-scrollbar {
            width: 8px;
        }
        
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        
        &::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.2);
            border-radius: 4px;
        }
    }

    .inputWithIconButton {
        min-height: 80px;
        align-items: flex-end;
        width: 100%;
        position: relative;
        background: white;
        border-radius: 16px;
        box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);

        .adapter-icon {
            margin-bottom: 10px;
        }

        .trigger {
            position: absolute;
            bottom: 12px;
            right: 12px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 8px;
            transition: all 0.2s ease;
            padding: 0 !important; // Added !important
            background: black;

            &:hover {
                background-color: rgba(0, 0, 0, 0.1);
            }

            svg {
                width: 24px;
                height: 24px;
                display: block;
                padding: 0 !important; // Added !important
                margin: 0 !important; // Added !important
            }

            // Target any potential wrapper elements around the SVG
            > * {
                padding: 0 !important;
                margin: 0 !important;
                display: flex !important;
                align-items: center !important;
                justify-content: center !important;
            }

            &:disabled {
                cursor: not-allowed;
                opacity: 0.5;
            }
        }
    }

    /* Mobile Styles */
    @media screen and (max-width: 48em) {
        &.persist {
            position: absolute;
            bottom: 20px;
            right: 20px;
            width: unset;
            z-index: 9999;
            padding: 0;
            border-radius: var(--radius-full);

            &.persist {
                max-width: unset !important;
            }

            &.isMobileOpen {
                height: 100vh;
                width: 100vw;
                border-radius: unset;
                bottom: 0;
                right: 0;

                .adapter-modal {
                    position: relative;

                    .adapter-modal-content {
                        bottom: 0;
                        top: unset;

                        .adapter-form-field-input {
                            position: absolute;
                            bottom: 10px;
                            right: 10px;
                            width: calc(100% - 20px);
                        }
                    }
                }
            }
        }
    }
}

/* Mobile Styles */
@media screen and (max-width: 48em) {
    .intention-inputbar.persist {
        position: absolute;
        bottom: 20px;
        right: 20px;
        width: unset;
        z-index: 9999;
        padding: 0;
        border-radius: var(--radius-full);

        &.persist {
            max-width: unset !important;
        }

        &.isMobileOpen {
            height: 100vh;
            width: 100vw;
            border-radius: unset;
            bottom: 0;
            right: 0;

            .adapter-modal {
                position: relative;

                .adapter-modal-content {
                    bottom: 0;
                    top: unset;

                    .adapter-form-field-input {
                        position: absolute;
                        bottom: 10px;
                        right: 10px;
                        width: calc(100% - 20px);
                    }
                }
            }
        }
    }

    .intention-inputbar-trigger {
        cursor: pointer;
        display: flex !important;
        width: 40px;
        height: 40px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: var(--spacing-lg);
        border-radius: var(--radius-md);
        background: var(--Base-950);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
}