.adapter-feedback {
    display: flex; 
    flex-direction: row; 
    align-items: start;

    border-radius: var(--radius-xs);
}

@media screen and (max-width: 48em) {
    .adapter-feedback {
        flex-direction: column;
        align-items: center;
    }    
}