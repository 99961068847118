.adapter-ul,
.adapter-ol {
    margin-left: 1em;
    text-align: left;

    .adapter-li {
        margin-left: 1em;

        &::marker {
            font-size: 1.5em;
        }
    }
}

.adapter-ul {
    list-style: disc;
}

.adapter-link {
    text-decoration: none;
    cursor: pointer;
    color: var(--Base-700);
    font-weight: 570;

    &:hover {
        text-decoration: underline;
    }
}

.adapter-typography-text,
.adapter-typography-headtext {
    display: block !important;

    .adapter-icon {
        display: inline-block;
    }
}