.adapter-modal {
    .adapter-modal-background {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        /* Semi-transparent black background */
        z-index: 1;
        /* Ensure it's behind the modal */
    }

    .adapter-modal-content {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 5px;

        /* Ensure it's above the background */
        z-index: 2;

        .adapter-card {
            max-height: 80vh !important;
        }
    }
}

/* 48em = 768px */
@media screen and (max-width: 48em) {
    .adapter-modal {
        
        .adapter-modal-content {
            top: 0;
            left: 0;
            transform: unset;
            width: 100%;
            margin-top: 50px;

            .adapter-card {
                margin: 0 auto;
            }
        }
    }
}