.adapter-notice {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-sm);

    &.warning {
        color: var(--Yellow-600) !important;

        .adapter-icon svg {
            stroke: var(--Yellow-600);
        }
    }

    &.error {
        color: var(--colors-error) !important;

        .adapter-icon svg {
            stroke: var(--colors-error);
        }
    }

    .adapter-notice-icon {

    }

    .adapter-notice-text {
        flex: 1;

        font-family: 'Saans';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
    }
}