.adapter-button {
  cursor: pointer;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  p {
    color: var(--colors-button-primary-text) !important;
  }

  padding: var(--spacing-md) var(--spacing-lg);
  gap: var(--spacing-xs);

  &.size-2xl {
      font-size: 18px;
      padding: 12px 20px;
      gap: 10px;
  }

  &.size-small {
      font-size: 12px;
      padding: var(--spacing-md) 11px;
      gap: 5px;
  }

  /* normal button */
  border-radius: var(--radius-md);
  //border: 1px solid var(--colors-button-primary-border);
  background: var(--colors-button-primary-bg);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  &:hover {
      border-radius: var(--radius-md);
      //border: 1px solid var(--colors-button-primary-boder-hover);
      background: var(--colors-button-primary-bg-hover);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  &:focus {
      border-radius: var(--radius-md);
      //border: 1px solid var(--colors-button-primary-border);
      background: var(--colors-button-primary-bg);
      box-shadow: 0px 0px 0px 4px rgba(158, 119, 237, 0.24), 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  &:disabled {
      border-radius: var(--radius-md);
      //border: 1px solid var(--colors-button-primary-border-disabled);
      background: var(--colors-button-primary-bg-disabled);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

      p {
        color: var(--colors-button-primary-text-disabled);
      }

      cursor: not-allowed;
  }

  &.plain {
    //border: 1px solid transparent;
    background: transparent;
    box-shadow: none;

    p {
        color: var(--adapter-gray-light-500);
    }

    &:hover {
        background: var(--adapter-gray-light-200);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
  
    &:focus {
        background: var(--adapter-gray-light-200);
        box-shadow: 0px 0px 0px 4px rgba(158, 119, 237, 0.24), 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
  
    &:disabled {
        background: var(--colors-button-primary-bg-disabled);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  
        p {
            color: var(--colors-button-primary-text-disabled);
        }

        cursor: not-allowed;
    }
  }

  &.reverse {
    //border: 1px solid #EAECF0;
    background: #F2F4F7;

    p {
        color: #6941C6;
    }
  }
}