.adapter-popover-trigger {
    cursor: pointer;
    display: inline-block;
    margin: 4px 0;
}

.adapter-popover-content {
    padding: 10px;
    z-index: 9999;
    background: #2E3038;
    border: #2E3038 solid 1px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    text-align: left;
    color: #fff;
    max-width: 300px;
}

@media screen and (max-width: 48em) {
    .adapter-popover-trigger {
        display: none;
    }
}