/* src/components/NodeDot.css */
.node-dot {
  width: 20px; /* Adjust size as needed */
  height: 20px;
  border-radius: 50%;
  background-color: lightgray; /* Default/processing */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex-shrink: 0; /* Prevent shrinking in flex containers */
}

/* Status Colors */
.status-processing {
  background-color: #e0e0e0; /* Light grey */
}
.status-processing-complete {
  background-color: #a0a0a0; /* Darker grey */
}
.status-contemplating {
  background-color: #a0a0a0; /* Darker grey */
}
.status-contemplation-complete {
  background-color: #d1c4e9; /* Light purple */
}
.status-adapted.adapted-other {
  background-color: #d1c4e9; /* Light purple */
}
.status-adapted.adapted-top {
  background-color: #673ab7; /* Dark purple */
}

/* Container Styles (in NodeVisualizer.css or similar) */
/* src/components/NodeVisualizer.css */
.visualizer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px; /* Ensure space for layouts */
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden; /* Important for layout transitions */
}

.grid-layout {
  display: grid;
  grid-template-columns: repeat(10, auto); /* 10 columns */
  gap: 10px; /* Spacing between dots */
  width: fit-content; /* Adjust width to content */
}

/* Specific layout for the 'contemplating' phase - using relative/absolute */
.contemplating-layout {
  position: relative;
  width: 200px; /* Estimate size needed */
  height: 100px; /* Estimate size needed */
  /* Center the layout itself if needed */
   margin: auto;
}

.contemplating-layout .node-dot {
   position: absolute; /* Position dots absolutely within this container */
}


.adapted-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px; /* Spacing in the vertical line */
}