.adapter-help-menu-button {
    position: fixed;
    bottom: 20px;
    right: 20px;

    cursor: pointer;

    display: flex;
    padding: 14px;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-md);

    border-radius: var(--radius-full);
    border: 1px solid var(--colors-button-secondary-border);
    //background: var(--colors-bg-primary);
    background: var(--adapter-gray-light-500);

    /* Shadows/shadow-md */
    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10);

    p {
        height: 20px;
        width: 20px;
        line-height: 20px !important;
        text-align: center;
        color: var(--White) !important;
    }

    &:hover {
        border-radius: var(--radius-full);
        border: 1px solid var(--Colors-Border-border-brand);
        background: var(--colors-bg-primary);

        /* Focus rings/ring-brand */
        box-shadow: 0px 0px 0px 4px rgba(158, 119, 237, 0.24);

        p {
            color: #7549F2 !important;
        }
    }
}

.adapter-help-menu {
    position: absolute;
    right: 18px;
    bottom: 75px;
    width: 220px;

    display: flex;
    flex-direction: column;
    gap: var(--spacing-xl);

    padding: var(--spacing-xl);
    border-radius: var(--radius-xl);
    border: 1px solid var(--Echo-200);
    background: var(--colors-bg-primary);

    z-index: 999;
}

@media screen and (max-width: 48em) {
    .adapter-help-menu-button {
        display: none;
    }
}