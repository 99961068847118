.disconnect-confirm {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .disconnect-confirm-head {
        margin-bottom: 28px;
    }

    .disconnect-confirm-body {
        h3 {
            color: var(--colors-text-text-primary-900);

            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
        }
        
        p {
            overflow: hidden;
            color: var(--colors-text-text-tertiary-600);
            text-overflow: ellipsis;

            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 20px;
        }
    }

    .disconnect-confirm-foot {
        display: flex;
        align-self: end;
    }
}