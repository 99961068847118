//PromptFlow.scss
.prompt-flow-container {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
}

.prompt-flow-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh; // Full viewport height
}

.input-section {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 770px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    z-index: 1;
}

.intention-list-section {
    position: absolute;
    top: calc(50% + 75px); // Adjust this value based on your InputBar height
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 800px;
    z-index: 0;
}

.nl-feedback {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

/* Mobile adjustments */
@media screen and (max-width: 48em) {
    .prompt-flow-content {
        height: calc(100vh - 80px); // Account for mobile padding/bars
    }
    
    .input-section {
        padding: 0 20px;
    }
    
    .intention-list-section {
        padding: 0 20px;
    }
}