.adapter-result-card {
  display: flex;
  padding: var(--spacing-2xl) var(--spacing-xl);
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  
  /* Enhanced styling */
  border-radius: 16px;
  border: 1px solid rgba(234, 236, 240, 0.8);
  background: #FFFFFF;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.05), 
              0px 8px 24px rgba(16, 24, 40, 0.03);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0px 4px 12px rgba(16, 24, 40, 0.08), 
                0px 12px 32px rgba(16, 24, 40, 0.05);
  }

  .adapter-result-card--content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: var(--spacing-3xl);
    align-self: stretch;
    width: 100%;

    .adapter-result-card--content--icon-bar {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      margin-bottom: var(--spacing-lg);
    }

    .adapter-result-card--content--title-elements {
      margin-bottom: var(--spacing-xl);
    }

    .adapter-result-card--content--hero-elements {
      margin-bottom: var(--spacing-xl);
      
      .adapter-result-card--content--hero-map {
        overflow: hidden;
        border-radius: 12px;
        box-shadow: 0px 2px 6px rgba(16, 24, 40, 0.04);
      }
    }

    .adapter-result-card--content--details {
      display: flex;
      justify-content: space-between;
      gap: var(--spacing-xl);
      margin: var(--spacing-lg) 0;
      
      // Style for startLabel and endLabel
      .rounded-md > .leading-tight {
        background-color: #F4F3FF;
        border-radius: 16px;
        padding: 4px 12px;
        display: inline-block;
      }
      
      // Style for startTime and endTime
      .rounded-md > .leading-tight {
        background-color: #F9F5FF;
        border: 1px solid #E9D7FE;
        border-radius: 20px;
        padding: 6px 14px;
        display: inline-block;
        margin-top: 8px;
      }
      
      // Reservation time specific styling
      &.reservation-time {
        align-items: center;
        
        > div > div:nth-child(1) {
          background-color: #F4F3FF;
          border-radius: 16px;
          padding: 4px 12px;
          display: inline-block;
        }
        
        > div > div:nth-child(2) {
          background-color: #F9F5FF;
          border: 1px solid #E9D7FE;
          border-radius: 20px;
          padding: 6px 14px;
          display: inline-block;
          margin-top: 8px;
        }
      }
    }

    .adapter-result-card--content--features {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
      gap: var(--spacing-lg);
      margin: var(--spacing-xl) 0;
      
      .w-5.h-5 {
        background: var(--primary-50, #F4F3FF);
        transition: background-color 0.2s ease;
      }
    }
    
    .adapter-result-card--content--CTA {
      padding: var(--spacing-xl);
      background: linear-gradient(to right, rgba(246, 247, 251, 0.9), rgba(251, 251, 255, 0.9));
      border-radius: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: var(--spacing-2xl);
      
      button {
        transition: transform 0.15s ease, box-shadow 0.15s ease;
        
        &:hover:not(:disabled) {
          transform: translateY(-1px);
          box-shadow: 0px 4px 8px rgba(107, 70, 193, 0.2);
        }
        
        &:active:not(:disabled) {
          transform: translateY(0);
        }
      }
    }

    .option-ranking-reason {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-lg);
      padding: var(--spacing-lg);
      background-color: rgba(249, 250, 252, 0.8);
      border-radius: 10px;
      margin-top: var(--spacing-lg);
    }
  }
  
  // Restaurant-specific styling
  &.restaurant-card {
    .restaurant-header {
      position: relative;
      height: 180px;
      background-color: #F3F4F6;
      margin: -24px -20px 20px;
      overflow: hidden;
      border-radius: 16px 16px 0 0;
      
      .restaurant-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.5s ease;
      }
      
      .cuisine-badge {
        position: absolute;
        left: 16px;
        bottom: 16px;
        background-color: rgba(255, 255, 255, 0.9);
        backdrop-filter: blur(4px);
        padding: 6px 12px;
        border-radius: 20px;
        font-size: 14px;
        font-weight: 500;
        color: #4B5563;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
      }
    }
    
    .restaurant-info {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-bottom: 20px;
    }
    
    .restaurant-name {
      font-size: 24px;
      font-weight: 600;
      color: #111827;
      margin: 0;
    }
    
    .restaurant-rating {
      display: flex;
      align-items: center;
      gap: 8px;
      
      .stars {
        display: flex;
        align-items: center;
        gap: 2px;
      }
      
      .star-filled {
        color: #F59E0B;
      }
      
      .star-empty {
        color: #D1D5DB;
      }
      
      .rating-text {
        font-size: 14px;
        color: #6B7280;
      }
    }
    
    .restaurant-location {
      display: flex;
      align-items: center;
      gap: 8px;
      color: #6B7280;
      font-size: 14px;
      margin-top: 4px;
    }
    
    .restaurant-tags {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      margin: 12px 0;
      
      .tag {
        background-color: #F4F3FF;
        color: #6D28D9;
        padding: 4px 12px;
        border-radius: 16px;
        font-size: 13px;
        font-weight: 500;
      }
    }
    
    .reservation-time {
      display: flex;
      align-items: center;
      gap: 8px;
      margin: 16px 0;
      padding: 12px 16px;
      background-color: #F9FAFB;
      border-radius: 8px;
      
      .time-icon {
        color: #7F56D9;
      }
      
      .time-text {
        font-weight: 500;
        color: #111827;
      }
    }
    
    .price-indicator {
      font-weight: 600;
      font-size: 18px;
      
      &.price-level-1 { color: #10B981; } /* Inexpensive */
      &.price-level-2 { color: #6D28D9; } /* Moderate */
      &.price-level-3 { color: #9D174D; } /* Expensive */
      &.price-level-4 { color: #7F1D1D; } /* Very Expensive */
    }
  }
}

.check-in-out-times {
  display: flex;
  margin: 16px 0;
  
  .times {
    font-size: 24px;
    font-weight: 600;
    color: #111827;
    line-height: 1.2;
  }
  
  .label {
    color: #6B7280;
    font-size: 16px;
    margin-bottom: 8px;
  }
}