//input.scss
.adapter-form-field-input {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: var(--spacing-sm);
    align-self: stretch;
    text-align: left;

    label {
        color: var(--colors-text-text-secondary-700);

        font-family: 'Saans';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }

    ::placeholder {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        flex: 1 0 0;

        overflow: hidden;
        color: var(--colors-text-text-placeholder);
        text-overflow: ellipsis;

        font-family: 'Saans';
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
        transition: opacity 0.2s ease; // Add transition for placeholder
    }
     &:focus::placeholder {
            opacity: 0; // Hide placeholder on focus
        }

    input,
    textarea {
        margin: unset;
        display: flex;
        padding: var(--spacing-md) var(--spacing-lg);
        align-items: center;
        gap: var(--spacing-md);
        align-self: stretch;

        overflow: hidden;
        color: var(--colors-text-text-primary-900);
        text-overflow: ellipsis;

        font-family: 'Saans';
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;

        border-radius: var(--radius-md);
        border: 1px solid var(--Base-600);
        box-shadow: 0px 0px 0px 4px transparent, 0px 1px 2px 0px transparent;

        &:focus {
            border-radius: var(--radius-md);
            border: 1px solid var(--colors-border-tertiary);

            /* Focus rings/ring-brand-shadow-xs */
            box-shadow: 0px 0px 0px 4px rgba(158, 119, 237, 0.24), 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        }

        &:disabled {
            border-radius: var(--radius-md);
            border: 1px solid var(--colors-border-primary);
            background: var(--colors-bg-active);

            /* Shadows/shadow-xs */
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        }

        &.error {
            border-bottom: unset !important;
            border-radius: var(--radius-md);
            border: 1px solid var(--colors-error) !important;

            /* Shadows/shadow-xs */
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

            &:focus {
                border-radius: var(--radius-md);
                border: 1px solid var(--colors-border-error);

                /* Focus rings/ring-error-shadow-xs */
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(193, 21, 116, 0.20);
            }
        }
    }

    input[type=checkbox] {
        border: 1px solid var(--colors-border-primary);
    }

    .hintText {
        color: var(--colors-text-text-tertiary-600);

        font-family: 'Saans';
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;
        margin: unset;
    }

    .errorText {
        color: var(--colors-text-text-error-primary-600);

        font-family: 'Saans';
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;
        margin: unset;
    }

    &.darkMode {
        background-color: var(--colors-bg-dark-mode);

        label {
            color: var(--colors-text-text-secondary-700);
        }

        ::placeholder {
            color: var(--colors-text-text-placeholder);
             transition: opacity 0.2s ease; // Add transition for placeholder
        }
         &:focus::placeholder {
                opacity: 0; // Hide placeholder on focus
            }

        input,
        textarea {
            color: var(--adapter-gray-light-200);

            border-radius: var(--radius-md);
            border: 1px solid var(--colors-border-dark-mode);
            background: var(--colors-bg-dark-mode);
            transition: background-color 0.2s ease; // Add transition

            &:focus {
                border: 1px solid var(--colors-border-quaternary);
                background: var(--White); // Change background on focus

                /* Focus rings/ring-brand-shadow-xs */
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(158, 119, 237, 0.24);
            }

            &:disabled {
                border: 1px solid var(--colors-border-dark-mode);
                background: var(--colors-bg-dark-mode-disabled);

                /* Shadows/shadow-xs */
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            }

            &.error {
                border: 1px solid var(--colors-border-error);
                background: var(--colors-bg-dark-mode);

                /* Shadows/shadow-xs */
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

                &:focus {
                    border: 1px solid var(--colors-border-error);
                    background: var(--colors-bg-dark-mode);

                    /* Focus rings/ring-error-shadow-xs */
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(240, 68, 56, 0.24);
                }
            }
        }

        .hintText {
            color: var(--colors-text-text-tertiary-600);
        }

        .errorText {
            color: var(--colors-text-text-error-primary-600);
        }
    }

    .react-datepicker__input-container {
        input {
            width: 100% !important;
        }
    }
}

.inputWithIconButton {
    display: flex;
    padding: var(--spacing-lg) var(--spacing-xl) var(--spacing-lg) var(--spacing-3xl);
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;

    border-radius: var(--radius-lg);
    border: 0.848px solid var(--adapter-gray-light-100);
    background: var(--White);

    textarea,
    textarea:focus {
        border-radius: var(--radius-lg);
        margin-right: -30px;
        padding-right: 30px;
        display: unset;
        width: 100%;
        resize: none;
        height: 52px;
    }

    .trigger {
        cursor: pointer;

        display: flex;
        width: 40px;
        height: 40px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: var(--spacing-lg);
        border-radius: var(--radius-md);
        background: var(--Base-950);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
}

.InputWithSuggestionButton {
    display: flex;
    flex-direction: row;

    input {
        flex: 1
    }

    .adapter-icon {
        margin: 10px;
    }
}

.DateInput {
    input {
        max-width: 32%;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
}