.adapter-header-with-button {
    display: flex;
    flex-direction: row;
    align-items: stretch;

    .adapter-header-with-button--content {
        flex: 1;
    }

    .adapter-header-with-button--button {
        text-align: right;
        margin-left: 20px;

        button {
            text-wrap: nowrap;
        }
    }
}

/* 48em = 768px */
@media screen and (max-width: 48em) {
    .adapter-header-with-button {
        flex-direction: column-reverse;

        .adapter-header-with-button--button {
            margin-bottom: 20px;
        }
    }
}