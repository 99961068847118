.adapter-network-analysis {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xl);
    max-width: 980px;
    text-align: left;

    p {
        font-size: 18px !important;
        font-weight: 380;
        line-height: 130% !important;
        font-family: 'Saans';
        padding-bottom: 1em;
    }

    ul,
    ol {
        margin-left: 1em;
        text-align: left;
        padding-bottom: 1em;

        li {
            margin-left: 1em;

            &::marker {
                font-size: 1.5em;
            }
        }
    }

    ul {
        list-style: disc;
    }

}