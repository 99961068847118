.adapter-landing-page-bg {
    background-color: var(--Echo-100);
}

.adapter-landing-page-icon {
    height: 48px;
    width: 48px;
    
    svg {
        height: 48px;
        width: 48px;
    }
}

.adapter-landing-page {
    // background: linear-gradient(0deg, rgba(92, 51, 204, 0.20) 0%, rgba(251, 200, 212, 0.20) 100%), #7839EE;
    // background-image: url(../../../public/landing_background.png);
    // background-position: top center;

    display: flex;
    //flex: 1;
    justify-content: center;
    align-items: flex-start;
    flex-shrink: 0;

    .landing-page--center {
        margin: 0 auto;
        width: 60vw;

        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 100px;

        .landing-page--inner-top {}

        .landing-page--inner-bottom {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 100px;

            text-align: center;

            .landing-page--inner-bottom-buttons {
                display: flex;
                align-items: center;
                gap: var(--spacing-4xl, 32px);
            }

        }
    }
}

/* 48em = 768px */
@media screen and (max-width: 48em) {
    .adapter-landing-page {
        #adapter-delight-arrowcontainer {
            display: unset !important;

            .content {
                padding: 0 20px;
            }
        }
    }
    
    .landing-page--inner-top {
        img {
            margin: 20px auto !important;
        }
    }

    .landing-page--center {
        width: 80vw !important;
        //gap: 50px !important;
    }

    .landing-page--inner-bottom {
        h1 {
            font-size: 36px !important;
            line-height: 44px !important;
            letter-spacing: -0.72px !important;
        }
    }   
}