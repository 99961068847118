.adapter-connected-accounts-wrapper {
    margin: 0 auto;
    padding: var(--spacing-lg)
}

.adapter-connected-accounts-header {

}

.adapter-connected-accounts {
    display: grid;
    gap: var(--spacing-md);
    grid-template-columns: repeat(2, 1fr);
    //margin: 0 auto;

    .adapter-card {
        max-width: 510px;
    }
}

@media screen and (max-width: 1200px) {
    .adapter-connected-accounts {
        display: grid;
        gap: var(--spacing-md);
        grid-template-columns: repeat(1, 1fr);
        margin: 0 auto;

        .adapter-card {
            
        }
    }   
}