.adapter-tabs {
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: var(--spacing-md);

    border-bottom: 1px solid var(--colors-border-secondary);
    margin: 48px var(--spacing-lg) 32px var(--spacing-lg);

    .adapter-tabs-tab {
        display: flex;
        height: 32px;
        padding: var(--spacing-xs) var(--spacing-lg);
        justify-content: center;
        align-items: center;

        &.active {
            border-bottom: 2px solid var(--colors-button-primary-border);
        }

        &.disabled {
            a {
                pointer-events: none;
                cursor: not-allowed;

                color: var(--colors-text-text-quaternary-500);
            }
        }
    }
}

/* 48em = 768px */
@media screen and (max-width: 48em) {
    .adapter-tabs {
        margin-top: 24px;
        margin-bottom: 16px;

        .adapter-tabs-tab {
            font-size: smaller;
        }
    }
}