.introduction {
    width: 60%;

    overflow: hidden !important;
    color: var(--adapter-gray-light-500) !important;
    text-align: center !important;
    text-overflow: ellipsis !important;

    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 18px !important;
}