.adapter-intention {
    position: relative;
    outline: none;
    display: flex;
    flex-direction: row;
    padding: 17px 15px;
    align-items: center;
    gap: 12px;
    border-radius: var(--radius-md);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    width: 100%;
    background-color: var(--Echo-200);

    &.selected {
        border: 1px solid var(--adapter-gray-light-950);
        transition: all 0.2s ease-in-out;
        background: var(--Echo-100);
        background: linear-gradient(to right,
                var(--Echo-100) 8%,
                var(--Echo-100) 18%,
                var(--Echo-100) 33%);
    }

    &:hover {
        .adapter-icon {
            path {
                stroke: var(--adapter-gray-light-950) !important;
            }
        }

        border: 1px solid var(--adapter-gray-light-950);

        .adapter-intention-content {
            p {
                color: var(--adapter-gray-light-950);
            }
        }
    }

    &:focus {
        .adapter-icon {
            path {
                stroke: var(--Colors-Adapter-Purple-500) !important;
            }
        }

        border: 1px solid var(--Colors-Adapter-Purple-500);

        .adapter-intention-content {
            p {
                color: var(--Colors-Adapter-Purple-500);
            }
        }
    }

    .adapter-icon {
        display: flex;
        width: 48px;
        height: 48px;
        padding: 12px;
        justify-content: center;
        align-items: center;
        border-radius: var(--radius-full);
        transition: all 0.2s ease-in-out;
        flex-shrink: 0;
        background-color: var(--Base-50);

        &.info {
            background-color: unset;
            border: unset;
            box-shadow: unset;
            position: absolute;
            bottom: 5px;
            right: 5px;
            padding: 0px;
            height: 24px;
            width: 24px;
        }
    }

    .adapter-intention-content {
        overflow: hidden;
        flex: 1;
        min-width: 0; // Helps with text overflow
        height: 60px;
        line-height: 60px;

        p {
            display: inline-block !important;
            text-align: left;
            vertical-align: middle;
            text-wrap: wrap;
            text-overflow: ellipsis;
        }
    }

    .icon-placeholder {
        width: 48px;
        height: 48px;
        flex-shrink: 0;
    }

    p.animated-background:nth-child(2) {
        margin-top: 5px;
        width: 80%;
    }
}

.adapter-intention-list {
    &.desktop {
        display: flex;
        align-items: stretch;
        gap: var(--spacing-xl);
        align-self: stretch;

        .adapter-intention {
            max-width: 300px;
        }
    }

    &.mobile {
        width: 100%;
        max-width: 300px;
        margin: 0 auto;
    }
}

.adapter-intention-tooltip {
    display: flex;
    width: 268px;
    padding: var(--spacing-md);
    flex-direction: column;
    align-items: flex-start;
    border-radius: var(--radius-md);
    background: var(--Base-200);
    margin: 0 auto;

    .adapter-intention-tooltip-inner {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--spacing-md);
        align-self: stretch;
    }
}

// Loading animation
@keyframes shimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

.animated-background {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    background: var(--adapter-gray-light-100);
    background: linear-gradient(to right,
            var(--adapter-gray-light-100) 8%,
            var(--adapter-gray-light-200) 18%,
            var(--adapter-gray-light-100) 33%);
    background-size: 800px 104px;
    position: relative;
}

// Media Queries
@media screen and (max-width: 48em) {
    .adapter-intention-list {
        &.desktop {
            display: none;
        }

        &.mobile {
            display: block;
        }
    }

    .adapter-intention {
        margin: 0 auto;

        .adapter-intention-content {
            width: auto;
            min-width: 0;
            flex: 1;
        }
    }
}

@media screen and (min-width: 48em) {
    .adapter-intention-list {
        &.desktop {
            display: flex;
        }

        &.mobile {
            display: none;
        }
    }
}

// for 'react-slick', add padding on mobile between items
.slick-slide {
    padding: 0 10px;
}

.slick-list {
    margin: 0 -10px;
}