.adapter-baseform {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: var(--spacing-2xl);
    align-self: stretch;

    &.sign-in-form {
        min-width: 50%;
        max-width: 360px; 
        margin: 0 auto;
    }
}

/* 48em = 768px */
@media screen and (max-width: 48em) {
    .sign-in-form {
        min-width: unset !important;
        max-width: unset !important; 
        margin: unset !important;
    }
}