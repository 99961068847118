.closeBtnWrapper {
    display: none;
}

.adapter-leftnav {
    transition: width 0.3s ease;
    width: var(--nav-width-expanded);
    border-right: 1px solid var(--colors-border-secondary);
    background: var(--colors-bg-primary);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 40px 0 24px 20px;
    gap: var(--spacing-md);

    &:not(.open) {
        padding: 24px 8px;

        .adapter-navlink > .adapter-typography-text {
            display: none !important;
        }
    }

    .adapter-navlink {
        padding: 8px 16px 0px 6px;
        background: var(--colors-bg-primary);
        cursor: pointer;
        transition: all 0.3s ease;
        display: flex;
        align-items: center;
        white-space: nowrap;
        text-decoration: none;
        color: var(--colors-text-text-secondary-700);
        
        width: 100%;
        position: relative;

        &:hover,
        &.active {
            border-radius: var(--radius-sm);
            background: var(--White);
            color: var(--Purple);

            svg path {
                fill: var(--Purple);
            }
        }

        svg {
            width: 24px;
            height: 24px;
            display: block;
            flex-shrink: 0;
            position: relative;
            z-index: 1;
        }

        .adapter-typography-text {
            margin-left: 12px;
        }
    }

    .collapse-button {
        width: 100%;
        height: 40px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease;
        padding: 8px;
        margin-top: auto;

        &:hover {
            svg {
                stroke: var(--Purple);
            }
        }

        svg {
            width: 24px;
            height: 24px;
            transition: stroke 0.2s ease;
            stroke: var(--colors-text-text-secondary-700);
            margin-left: 4px;
        }
    }

    .divider {
        border-bottom: 1px solid var(--colors-border-secondary);
        margin: 5px 20px 5px 0;
        transition: opacity 0.3s ease;
    }
}

/* Mobile Styles (48em = 768px) */
@media screen and (max-width: 48em) {
    .closeBtnWrapper {
        display: block;
        position: fixed;
        height: 24px;
        width: 24px;
        top: 10px;
        left: 10px;
        z-index: 1000;

        margin-top: constant(safe-area-inset-top);
        margin-top: env(safe-area-inset-top);

        &.open {
            right: 20px;
        }

        .adapter-icon {
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    .adapter-leftnav {
        transform: translateX(-100%);
        
        &.firstRender {
            transition: none;
        }
        
        padding-top: calc(constant(safe-area-inset-top) + 40px);
        padding-top: calc(env(safe-area-inset-top) + 40px);

        .collapse-button {
            svg {
                margin-left: 0;
            }
        }

        &:not(.open) {
            width: 0 !important;
            padding: 24px 0;
        }

        &.open {
            transform: translateX(0);
            background: var(--colors-bg-primary, #FFF);
            border-right: 1px solid var(--colors-border-secondary);

            .adapter-navlink {
                .adapter-typography-text {
                    display: inline-block;
                }
            }

            .collapse-button {
                display: flex;
            }
        }
    }
}