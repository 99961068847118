.visual {
    position: relative;
    height: 2px;
    background: linear-gradient(90deg, rgba(236, 238, 246, 0.8) 0%, rgba(226, 229, 245, 0.9) 50%, rgba(236, 238, 246, 0.8) 100%);
    margin: 14px;
    flex: 1;
    border-radius: 2px;

    display: flex;
    flex-direction: row;
    gap: var(--spacing-lg);
    justify-content: space-evenly;

    > div {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #7F56D9;
        transform: translateY(-2px);
        box-shadow: 0px 0px 0px 2px rgba(127, 86, 217, 0.15);
        transition: transform 0.2s ease;
        
        &:hover {
            transform: translateY(-3px) scale(1.2);
        }
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #7F56D9; 
        top: -3px;
        box-shadow: 0px 0px 0px 2px rgba(127, 86, 217, 0.15);
    }

    &::before {
        left: 0;
    }

    &::after {
        right: 0;
    }
    
    /* Add a pulsing animation to the dots */
    @keyframes pulse {
        0% { box-shadow: 0 0 0 0 rgba(127, 86, 217, 0.4); }
        70% { box-shadow: 0 0 0 4px rgba(127, 86, 217, 0); }
        100% { box-shadow: 0 0 0 0 rgba(127, 86, 217, 0); }
    }
    
    .active-flight-indicator {
        animation: pulse 2s infinite;
    }
}

/* Add styling for the airline logo */
.airline-logo {
    width: 32px;
    height: 32px;
    object-fit: contain;
    border-radius: 50%;
    padding: 3px;
    background-color: white;
}