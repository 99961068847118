@import "./css_animation.scss";
@import "./colors.scss";
@import "./fonts.scss";
@import "./layout.scss";

html,
body {
    background: var(--White);
    color: var(--Blacker);

    font-family: 'Saans';
    font-style: normal;

    margin: 0;
    padding: 0;
}

footer {
    text-align: right;
    padding: var(--spacing-2xl) 32px;

    p {
        color: var(--colors-text-text-tertiary-600);

        font-family: 'Saans';
        font-size: 14px !important;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;
    }
}

iframe {
    width: 400px;
}

.desktopOnly {}

.mobileOnly { display: none; }

/* 48em = 768px */
@media screen and (max-width: 48em) {
    .mobileOnly { display: unset !important; }

    .desktopOnly { display: none !important; }
}