.adapter-prompt-comprehension {
    display: block;
    max-width: 650px; 

    .attribute-group {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: var(--spacing-sm);
        width: 100%;
    }

    .adapter-pill {
        display: inline-block;
        margin-top: var(--spacing-xs);
    }

    .adapter-pill::first-letter {
        text-transform: capitalize;
    }

    .category {
        color: var(--adapter-gray-light-950);
        font-family: 'Saans';
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
    }

    .comprehension-feedback {
        border: 1px solid var(--adapter-gray-light-200);
        border-radius: var(--radius-xs);
        padding: var(--spacing-md) var(--spacing-lg);
        margin-top: 8px;

        display: inline-flex;
        flex-direction: row;

        p {
            color: var(--adapter-gray-light-500) !important;
        }

        .adapter-icon {
            margin-left: var(--spacing-lg);
            cursor: pointer;
        }
    }
}

/* 48em = 768px */
@media screen and (max-width: 48em) {
    .adapter-prompt-comprehension {
        .attribute-group {
            display: unset;
            align-items: unset;

            padding-bottom: var(--spacing-2xl);

            &:last-of-type {
                padding-bottom: 0;
            }
        }
    }
}