.responsive-card {
    width: 50%;
}

.onboarding-stats {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}

.onboarding-progress {
    max-width: 100%;
    width: 40%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    letter-spacing: 0;
}

.onboarding-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}

.onboarding-text-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--spacing-md);
    letter-spacing: 0.3px;
}

.ingested-numbers {
    color: #7F56D9;
    font-weight: 900;
    font-family: 'Saans';
    text-align: right;
    line-height: 40px;
}

.total-numbers {
    color: 'black';
    font-family: 'Saans';
    line-height: 40px;
}

.onboarding-buttons {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    letter-spacing: 0.3px;
    margin-top: 10px;
}

@media (max-width:1000px) {
    .onboarding-stats {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .onboarding-buttons {
        justify-content: center;
    }

    .onboarding-text-row {
        justify-content: space-between;
        line-height: 24px;
        padding: 5px;
        letter-spacing: 0;
        align-items: flex-start;
    }

    .total-numbers,
    .ingested-numbers {
        line-height: 24px;
    }

    .onboarding-progress {
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .onboarding-text-container {
        width: 100%;
    }

    .responsive-card {
        width: 100%;
    }
}

@media (max-width:1000px) and (min-width: 650px) {
    .onboarding-text-container {
        width: 50%;
    }

    .responsive-card {
        width: 65%;
    }
}