.action-log {
    overflow: hidden;

    display: flex;
    flex-direction: column;
    position: relative;

    .action-log-container {
        overflow-y: hidden;
        scrollbar-gutter: stable;

        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: var(--spacing-md);

        &:hover {
            overflow-y: auto;
        }

        padding-right: var(--spacing-sm)
    }
    
    > * {
        text-align: left;
    }

    .action-log-toggle {
        position: absolute;
        top: 12px;
        right: -12px;
        cursor: pointer;

        border-radius: 6485.838px;
        background: var(--adapter-gray-light-100, #E4E4E7);
        box-shadow: 0px 0.667px 1.333px 0px rgba(16, 24, 40, 0.05);

        display: flex;
        padding: var(--spacing-md);
        justify-content: center;
        align-items: center;
        gap: var(--spacing-md);
    }

    &.collapsed {
        width: 0px;
        padding: 0px;
        // animation-name: collapseAnimation;
        // animation-iteration-count: 1;
        // animation-timing-function: ease-in;
        // animation-duration: 1s;
      }
      
      @keyframes collapseAnimation {
        0% {
          width: 350px;
          padding: 20px;
        }
      
        100% {
          width: 0px;
          padding: 0px;
        }
      }

    h2 {
        color: var(--adapter-gray-light-950);

        font-family: 'Tobias';
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
    }

    .action-log-item {
        display: flex;
        padding: 12px;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        gap: var(--spacing-md);
        cursor: pointer;

        border: 1px solid transparent;
        border-radius: var(--radius-sm);


        &:hover {
            border: 1px solid var(--adapter-gray-light-950) !important;

            .action-log-title-left,
            .action-log-prompt {
                color: var(--adapter-gray-light-950) !important;
            }

            .action-log-title-right {
                .adapter-icon > svg {
                    stroke: var(--adapter-gray-light-950) !important;
                }
            }
        }

        &.pending {
            border-radius: var(--radius-sm);
            border: 1px dashed var(--Adapter-Purple-500);
            background: linear-gradient(90deg, rgba(120, 115, 245, 0.20) 0%, rgba(236, 119, 171, 0.20) 100%);

            &.selected {
                border: 1px solid var(--Adapter-Purple-500);

                .action-log-prompt {
                    color: var(--Adapter-Purple-500) !important;
                }
    
                .action-log-title {
                    .action-log-title-left,
                    .action-log-title-right {
                        color: var(--Adapter-Purple-500) !important;
                    }
                }
            }

            .action-log-prompt {
                color: var(--Adapter-Purple-500);
            }

            .action-log-title {
                .action-log-title-left,
                .action-log-title-right {
                    color: var(--Adapter-Purple-500);
                }
            }
        }

        &.unsupported {}

        &.selected {
            border: 1px solid var(--Adapter-Purple-500);

            .action-log-title-left,
            .action-log-prompt {
                color: var(--adapter-gray-light-950) !important;
            }

            .action-log-title-right {
                .adapter-icon > svg {
                    stroke: var(--adapter-gray-light-950) !important;
                }
            }
        }

        .action-log-title {
            display: flex;

            justify-content: space-between;
            align-items: center;
            gap: var(--spacing-md);

            .action-log-title-left,
            .action-log-title-right {
                overflow: hidden;
                color: var(--adapter-gray-light-500);
                text-align: left;
                text-overflow: ellipsis;
                
                font-family: 'Saans';
                font-size: 10px;
                font-style: normal;
                font-weight: 300;
                line-height: 14px;

                .adapter-icon > svg {
                    stroke: var(--adapter-gray-light-500);
                }
            }

            .action-log-title-right {
                text-align: right;
            }
        }

        .action-log-item-name {
            flex: 1;
            overflow: hidden;
            color: var(--adapter-gray-light-600);
            text-overflow: ellipsis;
            white-space: nowrap;
            
            font-family: 'Saans';
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
        }

        .action-log-prompt {
            color: var(--adapter-gray-light-500);

            font-family: 'Saans';
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
        }
    }
}

/* 48em = 768px */
@media screen and (max-width: 48em) {
    .action-log-container {
        overflow-y: auto !important;
    }
}