.adapter-card {
    display: flex;
    min-height: 40px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 24px;

    padding: 24px;
    margin: 24px 0;

    border-radius: var(--radius-xl);
    border: 1px solid var(--colors-border-secondary);
    background: var(--colors-bg-primary);

    /* Shadows/shadow-xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}