#root {
  height: 100%;
  width: 100%;
  min-height: 100vh; 

  display: flex;
  align-items: stretch;
  overflow: hidden;

  position: absolute;
  top: 0;
  left: 0;
}