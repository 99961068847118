@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  padding: 0;
}

.fa-icon-sm,
.fa-icon-md,
.fa-icon-lg {
  display: block;
  margin: 2px;
}

.fa-icon-sm {
  width: 0.8rem;
  height: 0.8rem;
}

.fa-icon-md {
  width: 1.2rem;
  height: 1.2rem;
}

.fa-icon-lg {
  width: 1.6rem;
  height: 1.6rem;
}

.spinner {
  animation: rotate 1s linear infinite;
}

.tooltip-tail {
  position: absolute;
  top: -1.125rem;
  right: 0.875rem;
  width: 0;
  height: 0;
  border-color: transparent transparent red transparent;
  border-width: 10px;
  border-style: solid;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}