.adapter-options-table {
    display: block;

    .attribute-group {
        display: block;
        margin-bottom: var(--spacing-md);
    }

    .adapter-pill {
        display: inline-block;
        margin-top: var(--spacing-xs)
    }

    .adapter-pill::first-letter {
        text-transform: capitalize;
    }

    .category {
        color: var(--adapter-gray-light-950);
        font-family: 'Saans';
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
    }
}