.atlas-empty-state {
    max-width: 300px;
    margin: 0 auto;
    min-height: 200px;
    display: flex;
    align-items: center;
    flex-direction: column;
    align-self: center;
    text-align: center;

    .adapter-icon,
    svg {
        height: 190px;
        width: 190px;
    }
}