.adapter-account-display {
    position: relative;
    width: 460px;

    .disconnect {
        position: absolute;
        top: 0;
        right: 0;
    }

    .account-icon {
        margin-bottom: 10px;
    }
}

/* 48em = 768px */
@media screen and (max-width: 48em) {
    .adapter-account-display {
        width: 100%;
    }
}