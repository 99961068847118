.restaurant-results-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  margin-bottom: 32px;

  .results-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }
    
    .results-summary {
      display: flex;
      align-items: center;
      gap: 12px;
      
      .icon-container {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #18181B;
        display: flex;
        align-items: center;
        justify-content: center;
        
        svg {
          color: white;
        }
      }
      
      .text-container {
        white-space: break-spaces;

        h2 {
          font-size: 24px;
          font-weight: 600;
          color: #111827;
          margin: 0 0 4px 0;
        }
        
        p {
          color: #4B5563;
          margin: 0;
        }
      }
    }
  }

  .restaurant-results-grid {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }
  
  .restaurant-result-wrapper {
    border-radius: 16px;
    background-color: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.05);
    overflow: hidden;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0px 4px 12px rgba(16, 24, 40, 0.08);
    }
    
    .restaurant-header {
      background-color: white;
      padding: 32px 24px;
      position: relative;
      min-height: 160px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      
      .restaurant-name-overlay {
        // position: absolute;
        // top: 16px;
        // left: 16px;
        font-size: 32px;
        padding: 8px;
        font-weight: 600;
        color: #4B5563;
      }
      
      .info-pills {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        margin-top: auto;
        
        .info-pill {
          background-color: #F2F2F0;
          padding: 8px 16px;
          border-radius: 20px;
          font-size: 14px;
          font-weight: 500;
          color: #4B5563;
          display: flex;
          align-items: center;
          gap: 6px;
          
          &.best-match {
            color: #7F56D9;
            font-weight: 500;
          }
        }
      }
    }
    
    // Map preview with smooth animations
    .map-preview {
      position: relative;
      width: 100%;
      overflow: hidden;
      transition: height 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      
      &.expanded {
        height: 350px;
        margin-bottom: 0;
      }
    }
    
    .restaurant-content {
      padding: 24px;
    }
    
    .restaurant-rating {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 16px;
      
      .stars-row {
          display: flex;
          align-items: center;
        }

        .partial-star-wrapper {
          position: relative;
          display: inline-block;
          width: 24px; // Adjust based on your star size
          height: 24px;
        }

        .star-background {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        .star-filled {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          overflow: hidden;
        }

        .star-filled svg {
          position: absolute;
          left: 0;
        }

        .stars-row {
          display: flex;
          align-items: center;
          gap: 4px; // Optional: adds consistent spacing between stars
        }
      
      
      .rating-text {
        color: #6B7280;
        font-size: 15px;
      }
    }
    
    .restaurant-address-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
      flex-wrap: wrap;
      gap: 12px;
      
      .restaurant-location {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #4B5563;
        flex: 1;
        min-width: 0;
        
        svg {
          color: #6B7280;
          flex-shrink: 0;
        }
        
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      
      .map-toggle-button {
        display: flex;
        align-items: center;
        gap: 6px;
        padding: 8px 12px;
        background-color: white;
        border-radius: 8px;
        border: 1px solid #E5E7EB;
        color: #4B5563;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        white-space: nowrap;
        
        &:hover {
          background-color: #F9FAFB;
          transform: translateY(-1px);
          box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.08);
        }
        
        &:active {
          transform: translateY(0px);
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
        }
        
        &.active {
          background-color: #F4F3FF;
          color: #6D28D9;
          border-color: #E9D7FE;
        }
        
        svg {
          width: 16px;
          height: 16px;
          transition: color 0.2s ease;
        }
      }
    }
    
    .restaurant-website {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 20px;
      color: #4B5563;
      
      svg {
        color: #6B7280;
        flex-shrink: 0;
      }
      
      a {
        color: #6D28D9;
        text-decoration: none;
        
        &:hover {
          text-decoration: underline;
        }
      }
    }
    
    .divider {
      height: 1px;
      background-color: #E5E7EB;
      margin: 16px 0;
    }
    
    // Match comparison styling follows above the reservation button
    
    .reservation-cta {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px; 
      
      button {
        background-color: #18181B;
        color: white;
        border-radius: 8px;
        font-weight: 500;
        padding: 12px 24px;
        font-size: 16px;
        transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        
        &:hover {
          background-color: #27272A;
          transform: translateY(-1px);
          box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
        }
        
        &:active {
          transform: translateY(0);
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
  
  // No results styling
  .no-restaurant-results {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 48px 24px;
    background-color: #F9FAFB;
    border-radius: 16px;
    margin: 24px 0;
    
    .no-results-icon {
      margin-bottom: 24px;
      color: #9CA3AF;
      
      svg {
        width: 48px;
        height: 48px;
      }
    }
    
    h3 {
      font-size: 20px;
      font-weight: 600;
      color: #111827;
      margin-bottom: 12px;
    }
    
    p {
      font-size: 16px;
      color: #4B5563;
      max-width: 500px;
      margin-bottom: 24px;
    }
    
    .tips-container {
      background-color: #FFF;
      padding: 16px 24px;
      border-radius: 12px;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
      text-align: left;
      width: 100%;
      max-width: 500px;
      
      h4 {
        font-size: 16px;
        font-weight: 600;
        color: #111827;
        margin-bottom: 12px;
      }
      
      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        
        li {
          position: relative;
          padding-left: 24px;
          margin-bottom: 8px;
          font-size: 14px;
          color: #4B5563;
          
          &:before {
            content: '';
            position: absolute;
            width: 6px;
            height: 6px;
            background-color: #9E77ED;
            border-radius: 50%;
            left: 8px;
            top: 7px;
          }
          
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 48em) {
  .restaurant-address-row,
  .restaurant-location,
  .restaurant-website {
    flex-direction: column;
    white-space: break-spaces;
    text-align: center;
  }
}