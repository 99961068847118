.adapter-icon {
    height: 24px;
    width: 24px;
    padding: 2px;
    display: block;

    &.inline-block {
        display: inline-block;
    }

    svg {
        stroke: #98A2B3;
        height: 24px;
        width: 24px;

        &.noStroke {
            stroke: none !important;
        }
    }
}