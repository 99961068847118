.toggle-switch-container {
    display: flex;
    border-radius: 8px;
    padding: 4px;
    width: fit-content;
    margin-bottom: 20px;
    border-width: 1px;
}

.toggle-switch {
    padding: 10px 20px;
    cursor: pointer;
    border-radius:8px;
    transition: background-color 0.3s ease, color 0.3s ease;
    background-color: transparent;
    color: #333;
}

.toggle-switch.active {
    background-color: white;
    color: #5d3fd3;
}

.toggle-switch span {
    font-weight: bold;
}

@media screen and (max-width: 48em) {
    .toggle-switch-container {
        margin-bottom: 10px;

        .toggle-switch {
            padding: 8px;
            font-size: smaller;
        }
    }
}