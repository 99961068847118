@font-face {
    font-family: 'Tobias';
    font-style: normal;

    src: url('./fonts/Tobias/TobiasVF.woff2') format('woff2'),
        url('./fonts/Tobias/TobiasVF.woff') format('woff'),
        url('./fonts/Tobias/TobiasVF.ttf') format('truetype');
}

@font-face {
    font-family: 'Saans';
    font-style: normal;
    
    src: url('./fonts/Saans/Saans-VF.woff2') format('woff2'),
        url('./fonts/Saans/Saans-VF.woff') format('woff'),
        url('./fonts/Saans/Saans-VF.ttf') format('truetype');
}

.adapter-text-size-h1 {
    font-size: 128px !important;
    font-weight: 250;
    line-height: 90% !important;
    font-family: 'Tobias';
}

.adapter-text-size-h2 {
    font-size: 72px !important;
    font-weight: 250;
    line-height: 90% !important;
    font-family: 'Tobias';
}

.adapter-text-size-h3 {
    font-size: 56px !important;
    font-weight: 570;
    line-height: 100% !important;
    font-family: 'Saans';
}

.adapter-text-size-h4 {
    font-size: 40px !important;
    font-weight: 570;
    line-height: 100% !important;
    font-family: 'Saans';
}

.adapter-text-size-l {
    font-size: 20px !important;
    font-weight: 380;
    line-height: 130% !important;
    font-family: 'Saans';
}

.adapter-text-size-m {
    font-size: 18px !important;
    font-weight: 380;
    line-height: 130% !important;
    font-family: 'Saans';
}

.adapter-text-size-s {
    font-size: 16px !important;
    font-weight: 380;
    line-height: 130% !important;
    font-family: 'Saans';
}

.adapter-text-size-xs {
    font-size: 14px !important;
    font-weight: 380;
    line-height: 130% !important;
    font-family: 'Saans';
}

.adapter-font-color-primary-900 {
    color: var(--colors-text-text-primary-900) !important;
}

.adapter-font-color-gray-light-200 {
    color: var(--adapter-gray-light-200) !important;
}

.adapter-font-color-gray-light-300 {
    color: var(--adapter-gray-light-300) !important;
}

.adapter-font-color-gray-light-400 {
    color: var(--adapter-gray-light-400) !important;
}

.adapter-font-color-gray-light-500 {
    color: var(--adapter-gray-light-500) !important;
}

.adapter-font-color-gray-light-600 {
    color: var(--adapter-gray-light-600) !important;
}

.adapter-font-color-gray-light-700 {
    color: var(--adapter-gray-light-700) !important;
}

@media screen and (max-width: 1649px) {
    .adapter-text-size-h1 {
        font-size: 112px !important;
    }
    
    .adapter-text-size-h2 {
        font-size: 64px !important;
    }
    
    .adapter-text-size-h3 {
        font-size: 48px !important;
    }
    
    .adapter-text-size-h4 {
        font-size: 36px !important;
    }
}

@media screen and (max-width: 1249px) {
    .adapter-text-size-h1 {
        font-size: 72px !important;
    }
    
    .adapter-text-size-h2 {
        font-size: 56px !important;
    }
    
    .adapter-text-size-h3 {
        font-size: 40px !important;
    }
    
    .adapter-text-size-h4 {
        font-size: 32px !important;
    }
}

@media screen and (max-width: 799px) {
    .adapter-text-size-h1 {
        font-size: 64px !important;
    }
    
    .adapter-text-size-h2 {
        font-size: 48px !important;
    }
    
    .adapter-text-size-h3 {
        font-size: 36px !important;
    }
    
    .adapter-text-size-h4 {
        font-size: 28px !important;
    }
}