/* src/components/NodeVisualizer.css */

/* Main container for the whole visualization area */
.visualizer-container {
  display: flex;          /* Use flexbox for centering content */
  justify-content: center; /* Center the layout horizontally */
  align-items: center;    /* Center the layout vertically */
  min-height: 400px;      /* Ensure enough vertical space for layouts */
  width: 100%;            /* Take full width available */
  padding: 20px;          /* Add some padding around the visualization */
  box-sizing: border-box; /* Include padding in width/height calculation */
  overflow: hidden;       /* Crucial for smooth layout transitions, prevents scrollbars during animation */
  border: 1px solid #eee; /* Optional: Add a light border to see the container bounds */
  margin-top: 20px;       /* Add some space above the container */
  background-color: #f9f9f9; /* Optional: Light background for the container */
}

/* Layout for the initial 'processing' and 'processing-complete' phases */
.grid-layout {
  display: grid;
  /* Creates 10 columns, size automatically based on the content (dots) */
  grid-template-columns: repeat(10, auto);
  gap: 10px;              /* Spacing between the dots */
  width: fit-content;     /* Makes the grid container only as wide as needed */
  /* framer-motion's layout prop will handle animating transitions from/to this grid */
}

/* Layout for the 'contemplating' and 'contemplation-complete' phases */
.contemplating-layout {
  position: relative; /* Establishes a positioning context for absolute children */
  /*
   Define approximate dimensions needed to contain the absolutely positioned dots.
   These values depend heavily on the coordinates defined in `contemplatingPositions`
   in NodeVisualizer.tsx, the size of your dots (from NodeDot.css), and desired padding.
   Estimate based on the maximum 'left' and 'top' values + dot size + buffer.
   Example calculation: If max left is ~270px, dot width is 20px, add buffer (e.g., 30px) -> width ~320px
                       If max top is ~90px, dot height is 20px, add buffer (e.g., 30px) -> height ~140px
   Adjust these based on your actual `contemplatingPositions` array.
  */
  width: 320px;
  height: 140px;
  /* Center the layout container itself within the visualizer-container */
  margin: auto;
  /* framer-motion's layout prop on this div handles its own animation if needed,
     but the primary animation happens on the dots transitioning *into* this layout. */
}

/* Styles applied to individual node dots specifically when they are
   *inside* the .contemplating-layout container. */
.contemplating-layout .node-dot {
  position: absolute; /* Dots are positioned based on `top` and `left` style props */
  /* The actual top/left values are set dynamically via the `style` prop
     passed to the NodeDot component from the `contemplatingPositions` array. */
  /* Transitions (movement) are handled by framer-motion's `layout` prop on the NodeDot itself. */
}

/* Layout for the final 'adapted' phase */
.adapted-layout {
  display: flex;
  flex-direction: column; /* Stack dots vertically */
  align-items: center;    /* Center dots horizontally within the column */
  gap: 5px;              /* Spacing between the dots in the vertical line */
  /* framer-motion's layout prop will handle animating transitions to this column layout */
}