:root {
    --Paler: #CDD5D6;
    --Black: #000;
    --Blacker: #313131;
    --White: #FFFFFF;
    --Purple: #7549F2;
    
    --Yellow-600: #CA8504;

    --Base-50: #FCFCFA;
    --Base-100: #F2F2F0;
    --Base-200: #E2E2E1;
    --Base-300: #D2D2D0;
    --Base-500: #727271;
    --Base-600: #515150;
    --Base-700: #3F3F3F;
    --Base-950: #0C0A09;

    --Echo-100: #EDEAFB;
    --Echo-200: #DCDAF7;
    --Echo-950: #2B1859;

    --colors-error: #C11574;

    /* font colors */
    --colors-text-text-error-primary-600: #C11574;

    --colors-text-text-placeholder: #667085;

    --colors-text-text-primary-900: #101828;

    --colors-text-text-secondary-700: #344054;

    --colors-text-text-tertiary-600: #475467; 
    
    --colors-text-text-quaternary-500: #667085;

    --colors-text-text-senary-300: #D0D5DD;

    --adapter-gray-light-50: #F1F1F4;
    --adapter-gray-light-100: #E4E4E7;
    --adapter-gray-light-200: #C8C9D0;
    --adapter-gray-light-300: #ADAFB8;
    --adapter-gray-light-400: #9093A2;
    --adapter-gray-light-500: #73788C;
    --adapter-gray-light-600: #5C6070;
    --adapter-gray-light-700: #454854;
    --adapter-gray-light-900: #17181C;
    --adapter-gray-light-950: #0B0C0E;


    --Colors-Adapter-Purple-50: #F9F5FF;
    --Colors-Adapter-Purple-100: #DBD4FB;
    --Colors-Adapter-Purple-300: #B09FF6;
    --Colors-Adapter-Purple-400: #957BF4;
    --Colors-Adapter-Purple-500: #7549F2;
    --Colors-Adapter-Purple-600: #5C33CC;

    --Component-colors-Components-Avatars-avatar-contrast-border: rgba(0, 0, 0, 0.08);


    /* border colors */
    --colors-border-primary: #D0D5DD;
    --colors-border-secondary: #EAECF0;
    --colors-border-tertiary: #D6BBFB;
    --colors-border-quaternary: #957BF4;
    --colors-border-error: #FAA7E0;
    --colors-border-dark-mode: #333741;


    /* background colors */
    --Adapter-Paper-25: #FEFDF9;
    --Adapter-Paper-50: #FDFBF5;
    --Adapter-Paper-100: #FBF6EA;
    --Adapter-Paper-200: #F9F1DE;
    --Adapter-Paper-300: #F6ECD2;
    --Adapter-Paper-400: #F4E7C6;


    --colors-bg-primary: #fff;
    --colors-bg-secondary: #7549F2;
    --colors-bg-quaternary: #EAECF0;
    --colors-bg-dark: #000;
    --colors-bg-dark-mode: #0C111D;
    --colors-bg-dark-mode-disabled: #161B26;
    --colors-bg-active: #F9FAFB;

    --Colors-Border-border-brand: #D6BBFB;


    /* button colors: primary */
    --colors-button-primary-text: var(--Base-50);
    --colors-button-primary-text-disabled: var(--Base-50);

    --colors-button-primary-border: #7549F2;
    --colors-button-primary-boder-hover: #6941C6;
    --colors-button-primary-border-disabled: #EAECF0;

    --colors-button-primary-bg: var(--Base-950);
    --colors-button-primary-bg-hover: var(--Base-500);
    --colors-button-primary-bg-disabled: var(--Base-300);

    /* button colors: secondary */
    --colors-button-secondary-border: #D0D5DD;

    /* button colors: tertiary */
    --colors-button-tertiary-text: #6941C6;
}

.adapter-bg-base-100 {
    background-color: var(--Base-100) !important;
}

.adapter-text-echo-950 {
    color: var(--Echo-950) !important;
}

[type='checkbox']:checked, 
[type='radio']:checked {
    background-color: var(--Base-950) !important;
}