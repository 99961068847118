@import "~leaflet/dist/leaflet.css";

.leaflet-tooltip.transparent-tooltip {
    background-color: transparent;
    border: none;
    box-shadow: none;
}

.transparent-popup .leaflet-popup-content-wrapper,
.transparent-tooltip .leaflet-tooltip-content {
    background-color: transparent;
    border: none;
    box-shadow: none;
}

.leaflet-tooltip.transparent-tooltip::before {
    display: none;
}

.adapter-mapview {
    position: relative;

    input[type='date'] {
        color: var(--adapter-gray-light-950);
    }

    .adapter-button {
        &.filterOn {
            background-color: var(--Purple) !important;
        }
    }

    .leaflet-tile-pane {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
    }

    // disable ukraine flag: https://github.com/Leaflet/Leaflet/pull/8109
    .leaflet-control-attribution {
        display: none;
    }

    height: 650px;
    width: 100%;

    .svg-icon {
        &.best-match {
            path {
                stroke: var(--Purple) !important;
            }

            >div::before {
                color: var(--Purple) !important;
            }
        }

        >div::before {
            content: attr(data-before);

            //border: 1px solid red;
            display: block;
            text-align: center;
            font-size: 10px;
            line-height: 18px;
            height: 24px;
            width: 24px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0px;
        }
    }

    .adapter-mapview-timeline {
        position: absolute;
        background-color: var(--adapter-gray-light-950);
        color: var(--adapter-gray-light-50);
        padding: var(--spacing-md);
        bottom: 40px;
        left: 40px;
        min-width: 500px;
        z-index: 9999;
    }

    .leaflet-marker-icon.mapviewplayer {
        animation: pulse 3s ease-out;
        border-radius: var(--radius-full);
    }

    @keyframes pulse {
        0% {
            box-shadow: 0 0 0 0px rgba(149, 123, 244, 0.3);
        }

        100% {
            box-shadow: 0 0 0 20px rgba(149, 123, 244, 0);
        }
    }
}

.legend-buttons {
    .circle {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-right: 8px;
        background-color: #000;

        &.hotel { background-color: rgba(255, 0, 0, 1); }
        &.calendar { background-color: rgba(0, 255, 0, 1); }
        &.flight { background-color: rgba(255, 255, 0, 1); }
        &.train { background-color: rgba(0, 255, 255, 1); }
        &.restaurant { background-color: rgba(255, 0, 255, 1); }
        &.car { background-color: rgba(100, 100, 255, 1); }
    }
}