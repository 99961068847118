
.adapter-chat-title {
    padding: 20px 20px 0 20px;
}

.adapter-chat-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 20px 20px 20px;

    .agent-response {
        >div {
            display: inline-flex;
            margin-right: 10px;
            flex-direction: row;
            align-items: stretch;
            gap: 5px;
            font-size: 13px;
            line-height: 28px;
        }

        .adapter-icon {
            display: block;

            svg {
                stroke: unset;
            }
        }
    }

    .adapter-intention-option {
        display: flex;
        padding: var(--spacing-3xl) var(--spacing-3xl) var(--spacing-4xl) var(--spacing-3xl);
        flex-direction: column;
        align-items: flex-start;
        gap: var(--spacing-2xl);
        align-self: stretch;
        //width: 400px;

        border-radius: var(--radius-sm, 6px);
        border: 1px solid var(--Adapter-Paper-400, #F4E7C6);
        background: var(--Adapter-Paper-50, #FDFBF5);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        .option-price-header {
            position: relative;
            align-self: stretch;

            .option-price-header-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-start;
                gap: var(--spacing-md);

                h2 {
                    padding-top: 24px;
                    padding-left: 0;
                }

                p {
                    line-height: 24px !important;
                }
            }
        }

        .option-details {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: var(--spacing-lg);
            align-self: stretch;

            .option-details-title-wrapper {
                display: flex;
                align-items: center;
                gap: var(--spacing-xl);
                align-self: stretch;
            }

            .option-details-body-wrapper {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-end;
                align-self: stretch;
                gap: var(--spacing-xl) 22px;

                text-align: center;

                .duration {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: stretch;

                    color: var(--Adapter-Purple-400);
                    text-align: center;

                    font-family: 'Rand Medium';
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px;

                    .visual {
                        border-bottom: 1px solid #957BF4;
                    }

                    .layovers {
                        color: var(--Adapter-Purple-400);

                        font-family: 'Rand Medium';
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 14px;
                    }
                }
            }
        }
    }
}

/* 48em = 768px */
@media screen and (max-width: 48em) {
    .adapter-chat-title {
        padding: 20px;

        h4 {
            border-bottom: 1px solid var(--adapter-gray-light-950);
        }
    }

    .adapter-chat-wrapper {
        padding: 27px 20px 20px 20px;
    }

    .agent-response {
        display: grid;
        grid-template-columns: 1fr 2fr;
    }
}