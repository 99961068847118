@keyframes adapter-loading {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.adapter-modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    max-width: none !important;

    backdrop-filter: blur(8px);

    display: flex;
    align-items: center;
    justify-content: center;
}

.adapter-loading {
    display: flex;
    align-items: center;
    justify-content: center;

    path {
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform-origin: center center;

        animation: adapter-loading 1.5s infinite;
    }
}