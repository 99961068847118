.adapter-progress-wrapper {
    border-radius: 100px;

    background: #c7c4df;
    min-width: 100px;
}

.adapter-progress {
    border-radius: 100px;
    height: 10px;
    width: 100%;

    background: linear-gradient(90deg, var(--Base-950) 0%, var(--Base-50) 100%);
    background-size: 200% 200%;
    animation: Animation 1s ease infinite;

    &.solid {
        background: var(--Base-950);
    }
}

@keyframes Animation { 
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
}