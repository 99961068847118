.adapter-onboarding-step-header {
    --circle-size: 1.5em;
    --complete-circle-color: #5C33CC;
    --incomplete-circle-color: #73788C;
    --background-color: #EEEBFD;
    --spacing: clamp(0.25rem, 2vw, 0.5rem);

    width: 100%;

    display: flex;
    flex-direction: row;
    gap: 40px;
    align-items: center;

    ol {
        display: flex;
    }

    li {
        // clear conflicting CSS
        padding: 0;
        margin: 0;

        display: flex;
        flex-direction: column;
        flex: 1;
        text-align: center;

        &:before {
            --size: 3rem;
            content: "";
            display: block;
            width: var(--circle-size);
            height: var(--circle-size);
            border-radius: 50%;
            opacity: 0.5;
            margin: 0 auto 1rem;
            background-color: var(--incomplete-circle-color);
        }

        &:not(:last-child) {
            &:after {
                content: "";
                position: relative;
                top: calc(var(--circle-size) / 2);
                width: calc(100% - var(--circle-size) - calc(var(--spacing) * 2));
                left: calc(50% + calc(var(--circle-size) / 2 + var(--spacing)));
                height: 2px;
                background-color: var(--background-color);
                order: -1;
            }
        }

        &.activeStep,
        &.completedStep {
            &:before {
                background-color: var(--complete-circle-color);
            }
        }

        &.completedStep {
            &:not(:last-child) {
                &:after {
                    content: "";
                    position: relative;
                    top: calc(var(--circle-size) / 2);
                    width: calc(100% - var(--circle-size) - calc(var(--spacing) * 2));
                    left: calc(50% + calc(var(--circle-size) / 2 + var(--spacing)));
                    height: 2px;
                    background-color: var(--complete-circle-color);
                    order: -1;
                }
            }
        }
    }

    .stepper__title {
        font-weight: bold;
        font-size: clamp(1rem, 4vw, 1.25rem);
        margin-bottom: 0.5rem;
    }

    li p {
        padding-left: var(--spacing);
        padding-right: var(--spacing);

        color: var(--adapter-gray-light-950);
        text-align: center;
        font-family: 'Saans';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }
}

.onboarding-modal {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    min-height: 0;
    flex: 1;
    width: 100%;

    .onboarding-modal-head {
        margin-bottom: 28px;
    }

    .onboarding-modal-body {
        text-align: left;
        flex: 1;
        overflow-y: auto;
        padding: 5px;

        h4,
        p {
            margin-bottom: 1em;
        }

        .adapter-button p {
            margin-bottom: 0;
        }
    }

    .onboarding-modal-foot {
        padding-top: var(--spacing-md);
        display: flex;
        align-self: end;
    }
}

.welcome-icons {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-5xl);

    >div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--spacing-md);

        .adapter-icon,
        svg {
            height: 80px;
            width: 80px;
        }
    }
}

.connect-account-title {
    color: var(--adapter-gray-light-500);
}

.coming-soon-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--spacing-xl)
}

.connect-account-option {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-2xl);
    cursor: pointer;

    border-radius: var(--radius-xl);
    border: 1px solid var(--Base-600);
    background: var(--colors-bg-primary);

    /* Shadows/shadow-xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    padding: var(--spacing-xl) var(--spacing-2xl);
    align-items: center;
    align-self: stretch;

    &.coming-soon {
        background: unset;

        p {
            color: var(--adapter-gray-light-500);
        }
    }

    &.requested {
        background-color: var(--Colors-Adapter-Purple-300);
        pointer-events: none;
        cursor: not-allowed;
    }
}

.adapter-onboarding {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xl);
    max-width: 980px;

    .adapter-card {
        padding: 42px 35px 62px 35px !important;
        background-color: var(--Base-50);
    }
}

.onboarding-popup {
    .adapter-card {
        max-width: 100vw !important;
        padding: 24px !important;
    }
}

.account-option-wrapper {
    display: flex;
    padding: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;

    border-radius: 16px;
    background: var(--Base-White, #FFF);
}

@media screen and (max-width: 48em) {
    .adapter-onboarding {
        margin-bottom: calc(env(safe-area-inset-bottom) + 50px);

        .adapter-card {
            width: unset;
            max-width: 85vw;
        }
    }

    .adapter-onboarding-step-header {
        gap: 15px;

        li p {
            font-size: 10px;
        }
    }

    .welcome-icons {
        flex-direction: column;
    }

    .coming-soon-wrapper {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-md);
        width: 100%;
    }

    .account-option-wrapper {
        padding: 0;
    }
}