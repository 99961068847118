// frontend/src/styles/layout.scss

:root {
    // Spacing variables
    --spacing-none: 0px;
    --spacing-xs: 4px;
    --spacing-sm: 6px;
    --spacing-md: 8px;
    --spacing-lg: 12px;
    --spacing-xl: 16px;
    --spacing-2xl: 20px;
    --spacing-3xl: 24px;
    --spacing-4xl: 32px;
    --spacing-5xl: 40px;

    // Border radius variables
    --radius-xxs: 2px;
    --radius-xs: 4px;
    --radius-sm: 6px;
    --radius-md: 8px;
    --radius-lg: 10px;
    --radius-xl: 12px;
    --radius-full: 9999px;

    // Navigation variables
    --nav-width-expanded: 214px;
    --nav-width-collapsed: 54px;
    --nav-transition-duration: 0.3s;
}

.adapter-basepage {
    position: relative;
    display: flex;
    flex-direction: row;
    flex: 1;

    .adapter-basepage-inner {
        background-color: var(--Echo-200);
        display: flex;
        flex-direction: column;
        flex: 1;
        position: relative;

        overflow-y: auto;
        overscroll-behavior-y: contain;
    }
}

.adapter-fullscreen-content {
    background-color: var(--Echo-200);
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: var(--spacing-5xl);

    >* {
        max-width: 80%;
        margin: 0 auto;
        text-align: center;
    }
}

.adapter-fullscreen-purple {
    background: url('./svg/Arrow-mask-signup.svg') top left repeat, 
                linear-gradient(45deg, #53389E 0%, #7F56D9 100%);
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: var(--spacing-5xl);

    * {
        max-width: 800px;
        margin: 0 auto;
    }

    h1 {
        color: #FFF;
        text-align: center;
        font-family: 'Tobias';
        font-size: 72px;
        font-style: normal;
        font-weight: 700;
        line-height: 90px;
        letter-spacing: -1.44px;
    }
}

.adapter-two-col {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-self: normal;
    gap: var(--spacing-5xl);

    ul, ol {
        list-style: disc;
    }

    .adapter-two-col--title {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: left;
        gap: var(--spacing-5xl);

        >* {
            width: 80%;
        }
    }

    .adapter-two-col--content {
        flex: 1;
        max-width: 50%;
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: var(--spacing-2xl);
        text-align: left;
    }
}

.adapter-flexagon {
    min-height: 200px;
}

/* Mobile Responsive Styles */
@media screen and (max-width: 48em) {
    iframe {
        width: 200px;
    }

    .adapter-basepage-inner {
        padding-top: calc(constant(safe-area-inset-top) + 10px);
        padding-top: calc(env(safe-area-inset-top) + 10px);
    }

    .adapter-fullscreen-purple,
    .adapter-fullscreen-content {
        padding: var(--spacing-3xl) var(--spacing-3xl) var(--spacing-4xl);
        gap: var(--spacing-lg);

        h1 {
            font-size: 48px;
            line-height: 56px;
        }
    }

    .adapter-two-col {
        flex-direction: column;
        gap: var(--spacing-lg);

        .adapter-two-col--title {
            gap: var(--spacing-lg);
            align-items: start;

            > * {
                width: unset;
            }
        }

        .adapter-two-col--content {
            flex: 1;
            max-width: 100%;
        }
    }
}