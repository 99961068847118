.matchComparisonBarContainer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  padding: var(--spacing-md);
  border-radius: 16px;
  background-color: #DCDAF7;

  .title {
    font-size: 20px;
    font-weight: 600;
    color: #111827;
    margin-bottom: var(--spacing-xs);
  }

  .subtitle {
    font-size: 16px;
    color: #4B5563;
    margin-bottom: var(--spacing-lg);
  }

  .slider-wrapper {
    margin-bottom: var(--spacing-lg);
    position: relative;
  }

  .slider-container {
    background-color: white;
    border-radius: 32px;
    padding: 6px 12px;
    position: relative;
    margin: 0 10px;
  }

  .labelContainer {
    display: flex;
    justify-content: space-between;
    padding: 0 8px;
    margin-top: 8px;

    .label {
    color: var(--Base-950, #0C0A09);
    font-feature-settings: 'dlig' on;

    /* xss/Medium */
    font-size: 12px;
    font-style: normal;
    font-weight: 570;
    line-height: 130%; /* 13px */
    }
  }

  .dot-grid {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 5px;
  }
  
  .dot {
    width: 12px;
    height: 12px;
    background-color: #DCDAF7;
    border-radius: 50%;
    transition: all 0.2s ease;
    cursor: pointer;

    &:hover {
      transform: scale(1.2);
    }

    &.highlighted {
      background-color: #7F56D9;
    }
  }
  
  .reason-text {
    color: #492A87;
    font-size: 16px;
    line-height: 1.5;
    padding: 0 10px;
  }
}

.scoreTooltip {
  position: absolute;
  background: white;
  border-radius: 8px;
  padding: 10px;
  width: 180px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  z-index: 1000;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  animation: tooltipAppear 0.2s forwards;
  
  @keyframes tooltipAppear {
    0% {
      opacity: 0;
      transform: translateX(-50%) translateY(5px);
    }
    100% {
      opacity: 1;
      transform: translateX(-50%) translateY(0);
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -6px;
    border-width: 6px;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }

  .scoreValue {
    font-size: 13px;
    color: #4B5563;
    text-align: center;
    font-weight: 500;
  }
}